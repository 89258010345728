.scoring-systems-grid {
  display: grid;
  grid-template: 5rem auto / 80% 20%;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
}

.add-scoring-system {
  grid-area: 1/2 / 1/2;
}

.scoring-system-table {
  grid-area: 2/1 / 2/3;
}
