/* whenever these colors are updated, also update the colors in /src/config.colors */
:root {
  --primary-color: #234091;
  --secondary-color: #f4f6f8;
  --tertiary-color: #4f8335;
  --quaternary-color: lightgrey;
}

::placeholder {
  font-style: italic;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-style: italic;
}

.padding-0 {
  padding: 0;
}

.padding-0-5 {
  padding: 0.5rem;
}

.padding-1 {
  padding: 1rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.padding-left-1 {
  padding-left: 1rem;
}

.padding-right-1 {
  padding-right: 1rem;
}

.padding-top-0-5 {
  padding-top: 0.5rem;
}

.padding-bottom-0-5 {
  padding-bottom: 0.5rem;
}

.padding-left-0-5 {
  padding-left: 0.5rem;
}

.padding-right-0-5 {
  padding-right: 0.5rem;
}

.padding-left-right-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-2 {
  padding: 2rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-bottom-2 {
  padding-bottom: 2rem;
}

.padding-left-2 {
  padding-left: 2rem;
}

.padding-right-2 {
  padding-right: 2rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding-3 {
  padding: 3rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-5 {
  margin: 0.5rem;
}

.margin-1 {
  margin: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.react-quill {
  .ql-container {
    min-height: 200px !important;
    min-width: 570px !important;
    overflow-y: scroll !important;
    resize: vertical !important;
  }
}


.margin-2 {
  margin: 2rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.background-white {
  background: white;
}

.color-white {
  color: white;
}

.background-red {
  background: red;
}

.color-red {
  color: red;
}

.background-black {
  background: black;
}

.color-black {
  color: black;
}

.background-grey {
  background: grey;
}

.color-grey {
  color: grey;
}

.transition-padding-0-5 {
  transition: padding 0.5s;
}

.transition-padding-1 {
  transition: padding 1s;
}

.pointer {
  cursor: pointer;
}

.background-primary {
  background: var(--primary-color);
}

.background-secondary {
  background: var(--secondary-color);
}

.background-tertiary {
  background: var(--tertiary-color);
}

.background-quaternary {
  background: var(--quaternary-color);
}

.color-primary {
  color: var(--primary-color);
}

.color-secondary {
  color: var(--secondary-color);
}

.color-tertiary {
  color: var(--tertiary-color);
}

.color-quaternary {
  color: var(--quaternary-color);
}

.radius-1 {
  border-radius: 1rem;
}

.radius-2 {
  border-radius: 2rem;
}

.radius-3 {
  border-radius: 3rem;
}

.radius-0-5 {
  border-radius: 0.5rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-2 {
  font-size: 2rem;
}

.z10 {
  z-index: 10;
}

.z100 {
  z-index: 100;
}

.z1000 {
  z-index: 1000;
}

.thin-lightgrey-border {
  border: 0.1rem solid lightgrey;
}

.autoRubricValueRequiredError {
  background-color: rgba(255, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.3rem
}

.linebreak {
  white-space: pre-line;
}
.condition-group {
  border-radius: 10px;
  width: 100%;
  border: 2px solid blue;
  margin-left: 15px; 
  padding: 15px; 
  margin-top: 35px;
  overflow-x: auto;
}
.condition-group-title {
  margin-top: -40px;
  width: 110px;
  font-size: 12px;
  color: #234091;
  background: white;
}
.condition-group-rel {
  font-size: 12px; 
  font-weight: 600;
  margin-top: 15px !important;
  margin-bottom: 25px;
}
.condition-group-select {
  width: 65px;
  margin-left: 20px !important; 
}
.condition-group-delete {
  margin-top: -35px;
  margin-right: -8px;
  text-align: right;
}
.ant-popover  {
  z-index: 1055 !important;
}
.condition-group-new-group {
  margin-top: 10px;
}
.condition-group-add-cond {
  width: 80px !important; 
  margin-right: 10px !important;
  margin-left: 17px !important;
  font-size:12px !important; 
  font-weight:700 !important; 
  margin-top: 15px !important;
}

.condition {
  font-size: 11px;
  margin-top: 15px;
  display: flex;
  .ant-btn-icon-only.ant-btn-lg {
    height: 30px !important;
  }
}
.condition-question {
  width: 500px;
  margin-right: 5px !important;
  font-size: 11px;
}
.condition-operand {
  width: 85px;
  margin-right: 5px !important;
  font-size: 11px;
}
.condition-option {
  width: 400px !important; 
  margin-right: 5px !important;
  font-size: 11px;
}
.multi-input-label {
  font-family: Arial, serif !important;
   font-size: 85% !important;
}
.multi-input-tooltip {
  font-size: 2rem !important;
   padding: 0 1rem 0 0 !important;
}
.field-condition {
  margin-left: 20px;
}
.field-condition-question {
  width: 550px; margin-left: 20px;
}
.field-condition-question-labelText {
  margin-left: 10px;
  margin-right: 10px;
}
.field-condition-operand {
  width: 130px;
}
.label-question {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}
.label-question-label {
  width: 15rem !important;
}
.label-question-label-infoBox {
  width: 12rem !important;
}
.ql-editing {
  left: 0px !important
}
.ql-tooltip {
  left: 0px !important;
}
.addAction-rubric {
  border-radius: 2px;
  border: 1px solid #ccc;
  .condition-group {
    width: 98%;
  }
  .condition-group-delete {
    margin-top: -35px;
    .ant-btn-icon-only.ant-btn-lg {
      width: 35px !important;
      height: 35px !important;
    }
  }

}
.addAction-rubric-table-thead-action {
  width: 35px !important;
}
.addAction-rubric-table-thead-strategy {
  width: 250px
}
.addAction-rubric-table-thead-actionDesc {
  width: 650px
}
.addAction-rubric-table-thead-select {
  width: 30px
}
.addAction-rubric td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}
.addAction-rubric-select {
  text-align: center !important;
}
.addAction-rubric tr:nth-child(even) {
  background-color: rgb(206, 245, 245);
}
.addAction-rubric tr:nth-child(odd) {
  background-color: white;
}
.addAction-container {
  position: fixed;
   top: 35%;
   left: 20%; 
   max-height: 350px;
  overflow: auto;
  width: 950px;
  z-index:1049 !important; 
  padding: 20px;
  border: 1px solid #ccc;
  background: white !important;
  border-radius: 10px;
  
}
.addAction-container-save {
  top: 34% !important;
  left: 19% !important; 
  transition: all 0.3s linear;
  background: linear-gradient(135deg, #8e3d8e, #230f23);
}
.action-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  float: left;
}
.action-button-addaction {
  margin-right: 10px;
}
.ql-editing {
  left: 0px !important
}
.ql-tooltip {
  left: 0px !important;
}
.rubric-condition-group {
  margin-left: 20px;
  margin-top: 10px;
}
.rubric-line-divider {
  margin: .5rem;
}
.rubric-select-rubric {
  width: 400px !important;
}
.rubric-select-action {
  min-width: 350px
}
.rubric-select-action-button {
  min-width: 400px
}
.rubric-select-action-edit {
  min-width: 120px !important
}
.table-header {
  background: skyblue !important;
}
.multi-input-intake-form {
    margin-top: 5px !important;
    padding: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}
.rubric-label-info {
  display: flex;
}
.rubric-selection {
  margin-top: 7rem;
  text-align: center;  
  margin-left: -110px;
}
.rubric-associatedAction-label {
  margin-top: 7rem;
  margin-left: 210px
}

.flex-grow-1 {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}

.d-none{
  display:none !important;
}

.progress-text {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.question-container {
  margin-bottom: 1rem;
}

.question-title {
  font-size: 17px !important;
  font-weight: 700;
}
.input-container {
  width: 100%;
}

.linebreak {
  margin-bottom: 0.5rem;
}
.align-start {
  align-items: flex-start;
}

.valign-top {
  vertical-align: top;
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  font-size: 1.5em !important; /* Increase size */
  color: #234091; /* Change color */
  font-weight: bold !important;
}

.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: #1890ff; /* Change color on hover */
}

.ant-tabs-tab {
  border-radius: 10px 10px 0 0; /* Border radius for top-left and top-right */
  margin-right: 5px !important; /* Space between tabs */
  background: #f0f0f0; /* Default background color */
  padding: 10px 20px !important;
  transition: background 0.3s, color 0.3s;
}

.ant-tabs-tab{
  font-size: 14px;
  font-weight: bold;
}

.ant-tabs-tab-active {
  background: #234091 !important; /* Active tab background color */
  color: #fff !important; /* Active tab text color */
  font-size: 16px !important;
  font-weight: bold;
}

.ant-tabs-tab:hover {
  background: #d9d9d9; /* Background color on hover */
  color: #000;
}



.ant-tabs-nav-wrap {
  border-bottom: none; /* Remove default bottom border */
}
.input-ctrl-action {
  margin-bottom: 1rem;
}

.label-row {
  margin-bottom: 0.5rem;
}

.input-row {
  margin-bottom: 1rem;
}
.actionStrategy {
  margin:0px !important;
}

.form-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Adjust as needed */
}

.form-logo {
  max-width: 100px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
}

.question-container small{
  font-weight: 600;
  font-size: 13px;
}

/* Right div's scrollbar */
.right-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.right-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

.right-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

.right-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}
