:root {
  --transparent-background: rgba(0, 0, 0, 0.75);
  --darker-background: #323232;
  --selected-color: #6dbe44;
  --marked-color: #ffa800;
  --action-icon-dimmed-color: #ad8a8a;
}

.photo-viewer {
  /* height: 100vh; */
  position: fixed;
  z-index: 10;
  display: flex;
  flex-flow: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.photo-viewer .header-bar {
  background-color: #eeeeee;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 8rem;
}

.photo-viewer .header-bar .images-selected {
  font-weight: 600;
  color: #292929;
  font-size: 1.8rem;
  padding-right: 1rem;
  /*margin-right: 8rem;*/
}

.photo-viewer .header-bar .header-icon-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
}

.photo-viewer .header-bar .header-icon-button {
  min-width: 16rem;
}

.photo-viewer .header-bar .done-button {
  /*margin-left: auto;*/
  padding-left: 4rem;
  padding-right: 4rem;
}

.photo-viewer .main {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  background-color: var(--transparent-background);
}

.photo-viewer .image-selector-sidebar {
  height: 100%;
  overflow-y: scroll;
  background-color: var(--darker-background);
  width: 15%;
}

.photo-viewer .infinite-scroll {
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 0.5rem;
  justify-items: center;
}

/* Make the selectable images appear clickable. */
.photo-viewer .infinite-scroll * {
  cursor: pointer;
}

.photo-viewer .infinite-scroll .image-thumbnail-wrapper {
  width: 26rem;
  min-height: 14rem;
  padding: 1.5rem 4rem;
  padding-left: 2rem;
  height: 100%;
  /* Anchor point for image-actions. */
  position: relative;
}

.photo-viewer .infinite-scroll .image-thumbnail-wrapper .image-thumbnail {
  border: 3px solid transparent;
}

.photo-viewer .infinite-scroll .image-thumbnail-wrapper.selected .image-thumbnail {
  border-color: var(--selected-color);
}

.photo-viewer .infinite-scroll .image-thumbnail-wrapper.viewing {
  background-color: black;
}

.photo-viewer .infinite-scroll .image-thumbnail {
  width: 100%;
  height: 100%;
}

.photo-viewer .content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.photo-viewer .image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.photo-viewer .image {
  max-width: 90%;
  max-height: 90%;
  border: 6px solid transparent;
}

.photo-viewer .image.selected {
  border: 6px solid #6dbe44;
}

.photo-viewer .selection-info-bar {
  color: white;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 1rem 0;
  border: none;
  background-color: var(--darker-background);
  /* background-color: rgb(58, 61, 61); */
}

.photo-viewer .select-an-image-text {
  color: white;
  font-size: 4rem;
}

/* 
 * Image Action Styling 
 */

.photo-viewer .image-thumbnail-wrapper .image-actions {
  position: absolute;
  top: 1.8rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1.9rem;
}

/* Hide icons when an image hasn't loaded. */
.photo-viewer .image-thumbnail:not([src]) + .image-actions {
  display: none;
}

.photo-viewer .image-thumbnail-wrapper .image-actions > * {
  margin-bottom: 0.75rem;
}

.photo-viewer .image-thumbnail-wrapper .image-actions:last-child {
  margin-bottom: 0;
}

.photo-viewer .image-thumbnail-wrapper .image-action-icon {
  border: 2px solid transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 0px var(--action-icon-dimmed-color);
  /* Default to dimmer color when icons don't have their active styles applied. */
  color: var(--action-icon-dimmed-color);
  border-color: var(--action-icon-dimmed-color);
  transition: all linear 70ms;
}

.photo-viewer .image-thumbnail-wrapper .image-action-icon svg {
  margin: 4px;
}

.photo-viewer .image-action-icon.selected-icon.selected {
  color: white;
  background-color: var(--selected-color);
  border-color: var(--selected-color);
  box-shadow: 0px 0px 4px 0px var(--selected-color);
}

.photo-viewer .image-action-icon.marked-icon.marked {
  color: white;
  background-color: var(--marked-color);
  border-color: var(--marked-color);
  box-shadow: 0px 0px 4px 0px var(--marked-color);
}

/* Scrollbar Styling */

#image-selector-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--background-color);
}

#image-selector-sidebar::-webkit-scrollbar {
  width: 1rem;
  background-color: var(--background-color);
}

#image-selector-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(95, 95, 95);
}
