.title-bar {
  .button-container {
    display: flex;
    align-items: center;
  }
}

.title-bar-positioning {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgb(244, 246, 248);
  box-shadow: 5px -4px 8px 0px;
}
