.padding-5 {
  padding: 5rem;
}

.margin-right-0-5 {
  margin-right: 1rem;
}

.unpublish-warning-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 255, 0.4);
  color: white;
  font-weight: bold;
  font-size: 300%;
}

.unpublish-warning-message {
  position: sticky;
  top: 10rem;
  padding: 5rem;
  background: radial-gradient(black 10%, transparent 65%, transparent 25%);
}

.side-menu-wrapper {
  /* position: sticky;
	top: 0;
	right: 3rem;
	margin-left: 1rem;
	height: 75rem;
	z-index: 30;
	overflow: scroll; */
}

.form-settings-container {
  background: rgb(244, 246, 248);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-section-tab {
  border-bottom: 0.3rem solid #234091;
}
.section-tab {
  padding: 1rem 2rem 1rem 2rem;
  margin-bottom: 1rem;
}

.field-chooser-bar {
  position: fixed;
  right: 0;
  bottom: 0;
}

.photo-icon-container {
  margin-top: 2rem;
  padding-top: 1rem;
  text-align: center;
  border: 1px dashed #c4cdd5;
  background: #fbfbfb;
  width: 8rem;
  height: 8rem;
}

.duplicate-warning {
  font-weight: bold;
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  font-size: 2rem;
  border-radius: 1rem;
}

/* styles for Action Tab in Formbuilder */

.formAction {
  margin-left: 1rem !important;
  float: right !important;
}
.saveActionbtn {
  margin-left: 1rem !important;
  float: right !important;
  height: 30px !important;
  padding: 0 20px !important;
}
.addAction {
  padding: 30px;
  margin-top: 20px;
  background: rgb(251, 251, 251);
}
.addActionBg {
  width: 100%;
  padding-bottom: 10px;
  flex-direction: row;
  display: flex;
}
.actionStrategy {
  width: 86%;
  display: inline-block;
  margin-left: 15px;
  margin-top: -2px;
}
.actionStrategyLabel {
  font-family: Arial, serif;
  font-size: 14px;
  padding-top: 5px;
  margin-right: 16px;
}
.actionStrategyInput {
  width: 89%;
}
.actionDesc {
  width: 100%;
  flex-direction: row;
  padding-bottom: 5px;
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}
.actionDescLabel {
  font-family: Arial, serif;
  font-size: 14px;
  padding-top: 5px;
}
.actionDescValue {
  height: 75px;
  width: 1000px;
}
.actionDropdown {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  flex-direction: row;
  display: flex;
}
.actionDropdownDisplay {
  width: 31%;
  display: inline-block;
}
.actionDropdownLabel {
  width: 47%;
  display: inline-block;
  line-height: 40px;
}
.actionDropdownLabelProp {
  font-family: Arial, serif;
  font-size: 14px;
  padding-top: 5px;
  margin-right: 50px;
}
.actionDropdownValue {
  width: 53%;
  display: inline-block;
}
.actionTags {
  width: 30%;
  display: inline-block;
  /* float: right; */
}
.actionTagsLabel {
  font-family: Arial, serif;
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}
.actionTagsButton {
  font-size: 2rem;
  border: none;
  margin-bottom: 10px;
  margin-left: 15px;
}
.actionTagsAdd {
  width: 85%;
  flex-direction: row;
  float: right;
  padding-bottom: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.actionTagEdit {
  margin-right: 1rem;
}
.actionTagName {
  height: 30px;
  min-width: 200px;
  float: right;
}
.actionCommonClass {
  width: 430px;
  flex-direction: row;
  padding-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
  margin-top: -5px;
  justify-content: space-between;
}
.actionTagDesc {
  height: 40px;
  float: right;
}
.actionDelete {
  width: 100%;
  float: right;
}
.actionDeleteConf {
  margin-left: 1rem;
  float: right;
  margin-top: -10px;
  margin-right: -20px;
}
.actionTagNameDelete {
  height: 30px;
  float: right;
  margin-left: 10px;
}
.generatePDF {
  font-size: 20px;
  margin-bottom: 10px
}
.generatePDFLabel {
 margin-left: 5px;
  font-weight: 600
}

.custom-table .ant-table-tbody > tr:hover > td {
  background: none !important;
}

.custom-table .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
  max-width: 200px !important;
}

