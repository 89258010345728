@mixin card-style {
  border-radius: 4px;
  box-shadow: #00000024 1px 1px 4px 1px;
  padding: 1rem;
  text-align: left;
  background-color: white;
}

@mixin darker-section {
  background-color: #eeeeee;
  padding: 1rem;
  line-height: 2.5rem;
}

.apply-filters-dropdown-button {
  width: 50rem;
  padding: 1rem;
  justify-self: flex-end;
}

.apply-filters {
  width: 50rem !important;

  label {
    color: rgba(0, 0, 0, 0.65);
    display: block;
    height: 1rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  .filter-section {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
  }

  input {
    max-width: 20rem;
  }
}

.ticketing {
  .row {
    display: flex;
    flex-direction: row;
  }

  .ant-tabs {
    // Align tabs to the left.
    .ant-tabs-nav-scroll {
      display: flex;
    }

    // UI mocks want table and tabs to have white background.
    background-color: white;

    .ant-tabs-nav-wrap {
      // Left tab looks weird without this.
      margin-left: -4px;
    }
  }

  .ticket-list-filters {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 2rem;

    margin-left: 2rem;
    margin-bottom: 2rem;

    max-width: 70rem;

    .dropdown {
      height: auto;
    }
  }

  .ticket-list {
    margin: 2rem;

    tbody tr {
      cursor: pointer;
    }
  }

  .selectable-intake-failure {
    min-height: 3rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    margin-left: 0.2rem;

    .answer-row div.value-missing {
      margin-top: 0.2rem;
    }

    .checkbox {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
      transform: scale(1.2);
    }
  }

  .create-ticket {
    label {
      display: block;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    .intake-failure {
      margin-bottom: 2rem;
    }

    .input-field {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .update-ticket {
    label {
      display: block;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    .intake-failure {
      margin-bottom: 2rem;
    }

    .input-field {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .intake-failure {
    &.enhanced {
      background-color: #eeeeee;
      padding: 1rem;
      line-height: 2.5rem;
    }

    .section-title {
      font-weight: 600;
    }

    .value-missing {
      font-style: italic;
      font-size: 1.3rem;
    }

    .answer-row {
      display: flex;
      align-items: center;
    }
    .answer-row .answer-word {
      margin-right: 0.5rem;
    }
  }

  .ticket-details {
    display: flex;
    flex-flow: column;

    // article {
    //   display: grid;
    //   grid-template-columns: 11fr 4fr;
    //   column-gap: 2rem;
    // }

    .page-header {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;
      width: 100%;

      .edit-button {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }

    .section-heading {
      font-size: 2.2rem;
      font-weight: bold;
    }

    .subsection-title {
      color: lighten(black, 30%);
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .completed-work-details.none {
      @include darker-section();
      padding: 2rem;
      color: #808080;
      font-size: 1.6rem;
    }

    .completed-work-details {
      img {
        object-fit: contain;
        max-width: 100%;
        padding: 1rem;
      }

      .file-uploader {
        border: 1px dashed #bfbfbf;
        margin-bottom: 1rem;
        padding: 1rem;

        &.dragover {
          background-color: #eeeeee;
        }
      }

      .file-uploader .files {
        background-color: inherit !important;
        padding: 0 !important;
      }

      .image-upload-box {
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        font-size: 1.8rem;
        padding: 2rem 0;
      }

      .image-upload-box .row {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image-upload-box .arrow-icon {
        font-size: 1.8rem;
        margin-bottom: 0.4rem;
      }

      .image-upload-box .subtitle {
        display: block;
        font-style: italic;
        color: gray;
      }

      .files div {
        width: initial;
      }
    }

    margin: 0 2rem;

    .ticket-details-section {
      display: flex;
      flex-flow: column;
      margin-right: 2rem;
      @include card-style();
    }

    .comments-section {
      @include card-style();
      margin-top: 2rem;

      .comments {
        @include darker-section();
      }

      .comment {
        margin-top: 2rem;
        &:first-child {
          margin-top: 0rem;
        }
      }

      .comment * {
        display: block;
      }

      .comment-box {
        margin-top: 1rem;
        margin-bottom: -1rem;
      }

      .comment-button {
        margin-top: 2rem;
        margin-right: 1rem;
      }
    }

    .details-box {
      display: flex;
      flex-flow: column;
      flex-shrink: 1;
      @include card-style();
      min-width: 23rem;

      // Make all top-level divs have margins on the bottom unless they're the
      // first and last.
      & > div {
        margin-bottom: 2rem;

        &:first-child {
          margin-bottom: 0;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
      }

      .light-text {
        color: #808080;
      }

      .label-to-field-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .project-link {
        margin-bottom: 10rem;
      }
    }

    .image-row {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      img {
        &:nth-child(1) {
          padding-right: 2rem;
        }
        height: 25rem;
        object-fit: contain;
      }
    }

    .ticket-controls {
      button {
        border: none;
        display: block;
        margin-top: 2rem;
        padding: 0.8rem 0;
        font-size: 2rem;
        color: white;
        border-radius: 0.4rem;
        cursor: pointer;
      }

      .incomplete-assignee {
        background-color: #234091;
      }

      .submitted-creator {
        display: flex;
        flex-direction: column;

        button:first-child {
          background-color: #6dbe44;
        }
        button:last-child {
          background-color: #912240;
        }
      }
    }

    .status-tag {
      display: flex;
      align-items: center;
      border-radius: 10rem;
      padding: 1rem 1.6rem;
      color: white;
      font-size: 1.6rem;
      height: 3.2rem;

      &.status-submitted {
        background-color: darken($color: #6dbe44, $amount: 5);
      }
      &.status-submitted.role-creator {
        background-color: #8a8c8f;
      }
      &.status-incomplete {
        background-color: #bd943f;
      }
      &.status-rejected {
        background-color: #912240;
      }
      &.status-completed {
        background-color: #6dbe44;
      }
      &.status-closed {
        background-color: #6dbe44;
      }
    }
  }
}
