.intake-failure {
  .section-title {
    font-weight: 600;
  }

  .value-missing {
    font-style: italic;
    font-size: 1.3rem;
  }

  .answer-row .answer-word {
    margin-right: 0.5rem;
  }
}

.enhanced {
  background-color: #eeeeee;
  padding: 1rem;
  line-height: 2.5rem;
}

.subsection-title {
  color: lighten(black, 30%);
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.section-title {
  font-weight: 600;
}
