// Class representing elements that are the page's title, irrespective of the
// actual element being used. This has the benefit of allowing us to use h1
// elements for all page titles for proper accessibility, while still being able
// to control the appearance and not simply conform to the default h1 styling.
.page-title {
  text-align: left;
}

//
// Main App Layout
//
.app {
  text-align: center;
  height: 100%;
}
