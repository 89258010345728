//
// Header Styles
//

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

// The "final" sizing on these is still to be determined.
h1 {
  font-size: 3.4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 2.3rem;
}
h5 {
  font-size: 2rem;
}
h6 {
  font-size: 1.8rem;
}
