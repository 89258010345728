.grid-container {
  display: grid;
  grid-template: 8rem 8rem 3rem 4rem auto 6rem / 35% 35% 15% 15%;
}

.name {
  grid-area: 1/1 / 1/5;
}

.percent {
  grid-area: 2/1 / 2/5;
}

.blank {
  grid-area: 3/1 / 3/5;
}

.table-header-value {
  grid-area: 4/1 / 4/2;
}

.table-header-failures {
  grid-area: 4/2 / 4/5;
}

.field-rows {
  grid-area: 5/1 / 5/5;
}

.criticality-row {
  display: grid;
  grid-template-columns: 35% 40% 15% 15%;
  grid-column: 1/5;
}

.criticality-row-name {
  grid-column: 1/2;
  height: 3rem;
}

.criticality-row-limit {
  grid-column: 2/3;
  padding: 1rem;
}

.criticality-row-value {
  grid-column: 3/4;
}

.criticality-row-delete {
  grid-column: 4/5;
}

.add-row-button {
  grid-area: 6/1 / 6/2;
  align-self: end;
}

.field-wrapper {
  padding: 1.5rem 0 0.5rem 0;
  border-bottom: 0.1rem solid lightgrey;
}

.info-text {
  color: grey;
  font-size: 65%;
  text-align: left;
  padding: 0 0 0 0.2rem;
}
