/*
Holds styles that form the 'base'line of our application design. Very generic
styling goes here. 
*/

// Universal Box Sizing with Inheritance technique. This is a standard pattern
// for modern styling.
//
// Means things like padding and borders won't add to the width of an item. The
// inheritance further means that if you need to override the box-sizing on
// anything, you can still do so without friction.
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// Make rem units correspond to pixels by factor of ten.
html {
  font-size: 62.5%;
} /* =10px */
// Set default font-size to 14px via rems, where due to 62.5% scaling, 1.4rem == 14px.
body {
  font-size: 1.4rem;
} /* =14px */

// Standard html inputs should have no border.
input {
  border: none;
}
