.calendar .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

/* Each calendar-event sub-section should be comfortably spaced from one-another. */
.calendar-event form > * {
  margin-bottom: 2rem;
}

.calendar .calendar-button-menu {
  position: sticky;
  width: 100%;
  padding: 1rem 0;
  /* Make it look like the rest of the page. */
  background-color: #f4f6f8;
  /* Give it the appearance of floating slightly above the page. */
  box-shadow: rgba(0, 0, 0, 0.3) 4px -1px 8px 0px;
  top: 0;
  z-index: 3;
}

/* The following two rules override antd's default time-selection design in
which seconds are displayed. We only want minutes and hours, and hence get rid
of the last row, and resize the other two to be half-width instead of 1/3. */

.calendar .ant-calendar-time-picker-select {
  width: 50%;
}
.calendar .ant-calendar-time-picker-select:last-child {
  display: none;
}

/* Make the calendar click/drag selection highlight darker than full-calendar's default. */
.calendar .fc-highlight {
  background: hsla(190, 65%, 55%, 1);
}

.padding-top-bottom-1 {
  padding: 1rem 0 1rem 0;
}


.fc .fc-button[title="custom-label"] {
  background-color: transparent;
  border: none;
  color: #000; /* Set the text color as needed */
  cursor: default; /* Prevent pointer cursor */
  font-size: 16px; /* Adjust the font size as needed */
  padding: 0; /* Remove padding */
}