@mixin darker-section {
  background-color: #eeeeee;
  padding: 1rem;
  line-height: 2.5rem;
}

.section-heading {
  font-size: 2.2rem;
  font-weight: bold;
}

.subsection-title {
  color: lighten(black, 30%);
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.status-tag {
  display: flex;
  align-items: center;
  border-radius: 10rem;
  padding: 1rem 1.5rem;
  color: white;
  font-size: 1.5rem;
  height: 3rem;

  &.status-submitted {
    background-color: darken($color: #6dbe44, $amount: 5);
  }
  &.status-submitted.role-creator {
    background-color: #8a8c8f;
  }
  &.status-incomplete {
    background-color: #bd943f;
  }
  &.status-rejected {
    background-color: #912240;
  }
  &.status-completed {
    background-color: #6dbe44;
  }
  &.status-closed {
    background-color: #6dbe44;
  }
}

.light-text {
  color: #808080;
}

.comments {
  @include darker-section();
}

.comment {
  margin-top: 2rem;
  &:first-child {
    margin-top: 0rem;
  }
}

.comment * {
  display: block;
}

.comment-box {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.comment-button {
  margin-top: 2rem;
  margin-right: 1rem;
}
