@import '../styles/colors';

.analytics-page {
  .page-title {
    margin: 2rem;
    font-weight: 600;
  }

  .table-description {
    margin-top: 4rem;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0.75rem 0;
    margin-bottom: 0;

    $mix-weight: 80;
    &.better {
      background-color: mix($lighter-blue, green, $mix-weight);
    }
    &.worse {
      background-color: mix($lighter-blue, red, $mix-weight);
    }
  }
  .selector-label {
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;

    &.first {
      padding-left: 2rem;
    }
  }

  .selector {
    flex-grow: 5;
    padding-right: 1rem;
  }

  .ant-tabs-nav-scroll {
    text-align: left;
  }

  button.ant-btn {
    margin-right: 1rem;
  }

  .ant-table-thead > tr > th {
    height: 5px;
    padding: 5px;
  }
}

.js-plotly-plot .svg-container {
  margin: auto !important;
}
