.forms {
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .pageContainer {
    padding: 2rem;
    padding-right: 6rem;
    text-align: left;
  }

  .thick {
    padding: 0 50px;
  }

  .formBuilderItemSelected {
    border: 2px dashed #234091;
    border-radius: 3px;
    background-color: #f5fbfd;
    box-shadow: 0 0 6px 0 #234091;
  }

  .sectionItem {
    margin: 15px 0;
  }

  .formSetting {
    margin: 20px 0;
  }

  .formSettingActions {
    margin: 20px 0;
  }

  .followupContainer {
    margin: 10px 0;
  }

  // input {
  //     border: none;
  // }
}
